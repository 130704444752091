import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { useAuth } from './context/AuthContext'; // 假設你的 AuthContext 位於這個路徑
import { hasPermission } from './utils/permissions'; // 假設你的權限檢查函數位於 utils 目錄中

const CompanyTable = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1); // 追蹤當前頁數
  const [hasMore, setHasMore] = useState(true); // 是否還有更多資料
  const [sortConfig, setSortConfig] = useState({ key: 's_id', direction: 'desc' });
  const [showModal, setShowModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);  
  const observer = useRef();
  const lastRef = useRef();
  const { user } = useAuth(); // 取得使用者資訊
  const [successMessage, setSuccessMessage] = useState('');
  const token = localStorage.getItem('token');
  const [formData, setFormData] = useState({
    
  });

  

  useEffect(() => {
    fetchCompanies(page, sortConfig.key, sortConfig.direction, filter);
  }, [page, filter, sortConfig]);
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'; // 如果當前是升序，則切換為降序
    }
    setSortConfig({ key, direction }); // 更新排序狀態
    setCompanies([]); // 清空當前數據
    setPage(1); // 重置頁碼為第一頁
    fetchCompanies(1, key, direction, filter); // 使用新排序規則加載數據
  };

  
  // 讀取公司資料
  const fetchCompanies = async (page, sortKey = sortConfig.key, sortDirection = sortConfig.direction, filter) => {
    setLoading(true);
    try {
      const response = await axios.get('/api/companies', {
        params: { filter, page, sortKey, sortDirection },
        headers: { 'Authorization': `Bearer ${token}` }
      });
  
      if (response.data.length === 0 && page === 1) {
        setSuccessMessage('找不到符合條件的公司資料');
        setTimeout(() => setSuccessMessage(''), 3000);
      }
  
      setCompanies((prev) => (page === 1 ? response.data : [...prev, ...response.data]));
      setHasMore(response.data.length > 0);
      
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setLoading(false);
    }
  };
  

  

  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除這家公司嗎？')) {
      try {
        const response =await axios.delete(`/api/companies/${id}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        setCompanies((prev) => prev.filter((company) => company.s_id !== id));
        setSuccessMessage(response.data.message);
        setTimeout(() => setSuccessMessage(''), 3000); // 3 秒後清除成功消息
        
        fetchCompanies(currentPage); 

      } catch (error) {
        console.error('Error deleting company:', error);
      }
    }
    
  };

  const handleSave = async () => {
    const cleanedFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, value === '' ? null : value])
    );
    try {
      if (selectedCompany) {
        const response = await axios.put(`/api/companies/${selectedCompany.s_id}`, cleanedFormData, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        setSuccessMessage(response.data.message);
      } else {
        const response = await axios.post('/api/companies', cleanedFormData, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        setSuccessMessage(response.data.message);
      }
      setTimeout(() => setSuccessMessage(''), 3000); // 3 秒後清除成功消息
      fetchCompanies(1);
      setShowModal(false);
    } catch (error) {
      console.error('Error saving company:', error);
      alert('儲存公司時出現錯誤，請稍後重試');
    }
  };

  const openModal = (company = null) => {
    setSelectedCompany(company);
    if (company) {
      setFormData(company);
    } else {
      setFormData({
       
      });
    }
    setShowModal(true);
  };

  // 無限滾動邏輯
  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    });

    if (lastRef.current) {
      observer.current.observe(lastRef.current);
    }
  }, [loading, hasMore]);

  return (
    <div className="container-fluid mt-4">
           {/* 顯示成功消息 */}
    {successMessage && (
      <div className="alert alert-success" role="alert">
        {successMessage}
      </div>
    )}
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋公司資料..."
          value={filter}
          onChange={(e) => {
            setCompanies([]);
            setPage(1);
            setFilter(e.target.value);
          }}
        />
        {hasPermission(user, 'EDIT_ROLE') && (
        <Button variant="success" size="sm" className="me-2" onClick={() => openModal()}>
          新增
        </Button>
        )}
      </div>

      <Table striped bordered hover variant="dark" className="table-striped table-dark">
      <thead>
        <tr>
          <th onClick={() => handleSort('s_id')}>編號</th>
          <th onClick={() => handleSort('sm_name')}>類別</th>
          <th onClick={() => handleSort('s_com_name')}>公司名稱</th>
          <th onClick={() => handleSort('s_business_item')}>業務項目</th>
          <th onClick={() => handleSort('s_boss')}>負責人</th>
          <th onClick={() => handleSort('s_contact')}>聯絡人</th>
          <th onClick={() => handleSort('s_address')}>地址</th>
          <th onClick={() => handleSort('s_remark')}>備註</th>
          {hasPermission(user, 'EDIT_ROLE') && (
          <> 
          <th onClick={() => handleSort('s_tel')}>電話</th>
          <th onClick={() => handleSort('s_mobile')}>手機</th>
          <th>操作</th>
          </>
           )}
        </tr>
      </thead>

        <tbody>
        {companies.map((company, index) => (
              <tr key={`${company.s_id}-${index}`}>
      <       td>{company.s_id}</td>
              <td>{company.sm_name}</td>
              <td>{company.s_com_name}</td>
              <td>{company.s_business_item}</td>
              <td>{company.s_boss}</td>
              <td>{company.s_contact}</td>
              <td>{company.s_address}</td>
              <td>{company.s_remark}</td>
              {hasPermission(user, 'EDIT_ROLE') && (
              <> 
              <td>{company.s_tel}</td>
              <td>{company.s_mobile}</td>
              <td>
                <Button
                  variant="info"
                  size="sm" className="me-2" 
                  onClick={() => openModal(company)}
                >
                  編輯
                </Button>
                <Button
                  variant="danger" 
                  size="sm" className="me-2" 
                  onClick={() => handleDelete(company.s_id)}
                >
                  刪除
                </Button>
              </td>
              </>
           )}
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan="14" className="text-center">
                加載中...
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCompany ? '編輯公司' : '新增公司'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3">
              <Form.Label>類別</Form.Label>
              <Form.Control
                type="Number"
                value={formData.s_group}
                onChange={(e) =>
                  setFormData({ ...formData, s_group: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>公司名稱</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_com_name}
                onChange={(e) =>
                  setFormData({ ...formData, s_com_name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>業務項目</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_business_item}
                onChange={(e) =>
                  setFormData({ ...formData, s_business_item: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>負責人</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_boss}
                onChange={(e) =>
                  setFormData({ ...formData, s_boss: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>聯絡人</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_contact}
                onChange={(e) =>
                  setFormData({ ...formData, s_contact: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>電話</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_tel}
                onChange={(e) =>
                  setFormData({ ...formData, s_tel: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>手機</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_mobile}
                onChange={(e) =>
                  setFormData({ ...formData, s_mobile: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>地址</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_address}
                onChange={(e) =>
                  setFormData({ ...formData, s_address: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>備註</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.s_remark}
                onChange={(e) =>
                  setFormData({ ...formData, s_remark: e.target.value })
                }
              />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>編輯日期</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_edit_day}
                onChange={(e) =>
                  setFormData({ ...formData, s_edit_day: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>編輯用戶</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_edit_user}
                onChange={(e) =>
                  setFormData({ ...formData, s_edit_user: e.target.value })
                }
              />
            </Form.Group> */}
            
            {/*<Form.Group className="mb-3">
              <Form.Label>排序</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_seq}
                onChange={(e) =>
                  setFormData({ ...formData, s_seq: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>狀態</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_status}
                onChange={(e) =>
                  setFormData({ ...formData, s_status: e.target.value })
                }
              />
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" className="me-2" onClick={() => setShowModal(false)}>
            取消
          </Button>
          <Button variant="primary" size="sm" className="me-2"  onClick={handleSave}>
            儲存
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompanyTable;
