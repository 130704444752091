import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { useAuth } from './context/AuthContext'; // 假設你的 AuthContext 位於這個路徑
import { hasPermission } from './utils/permissions'; // 假設你的權限檢查函數位於 utils 目錄中


const LecturerTable = () => {
  const [lecturers, setLecturers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);  // 增加 page 來追蹤當前頁面
  const [hasMore, setHasMore] = useState(true); // 用來判斷是否有更多數據
  const [sortConfig, setSortConfig] = useState({ key: 'l_id', direction: 'desc' }); // 新增排序狀態
  const [showModal, setShowModal] = useState(false);
  const [selectedLecturer, setSelectedLecturer] = useState(null);
  const lastRef = useRef(); // 追蹤最後一個講師的 ref
  const { user } = useAuth(); // 取得使用者資訊
  const [successMessage, setSuccessMessage] = useState('');
  const token = localStorage.getItem('token');
  const [formData, setFormData] = useState({
    
  });
  const observer = useRef(); // 用於無限滾動的 IntersectionObserver
  
  useEffect(() => {
    fetchLecturers(page, sortConfig.key, sortConfig.direction, filter);
  }, [page, filter, sortConfig]);

   // 無限滾動邏輯
   useEffect(() => {
    if (loading) return;
    //if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1); // 滾動到底部後自動加載更多
      }
    });

    if (lastRef.current) {
      observer.current.observe(lastRef.current);
    }
  }, [loading, hasMore]);

  // 排序函數
const handleSort = (key) => {
  let direction = 'asc';
  if (sortConfig.key === key && sortConfig.direction === 'asc') {
    direction = 'desc'; // 如果當前是升序，則切換為降序
  }
  setSortConfig({ key, direction }); // 更新排序狀態
  setLecturers([]); // 清空當前數據
  setPage(1); // 重置頁碼為第一頁
  fetchLecturers(1, key, direction, filter); // 使用新排序規則加載數據
};

// 調用 API 獲取數據時使用排序參數
const fetchLecturers = async (page, sortKey = sortConfig.key, sortDirection = sortConfig.direction, filter) => {
  setLoading(true);
  try {
    const response = await axios.get('/api/lecturers', {
      params: { filter, page, sortKey, sortDirection }, // 傳遞排序參數
      headers: { 'Authorization': `Bearer ${token}` }
    });
    
    setLecturers((prevLecturers) => [...prevLecturers, ...response.data]); // 合併數據
    setHasMore(response.data.length > 0); // 如果返回數據長度為 0，則沒有更多數據
  } catch (error) {
    console.error('Error fetching lecturers:', error);
  }
  setLoading(false);
};

  // 刪除講師
  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除這位講師嗎？')) {
      try {
        // 將 axios.delete 的結果保存到 response 中
        const response = await axios.delete(`/api/lecturers/${id}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
  
        // 刪除講師後更新本地狀態，移除已刪除的講師
        setLecturers((prevLecturers) => prevLecturers.filter((lecturer) => lecturer.l_id !== id));
  
        // 使用後端返回的消息設置成功消息
        setSuccessMessage(response.data.message);
        setTimeout(() => setSuccessMessage(''), 3000);
        fetchLecturers(currentPage);
        
      } catch (error) {
        
          console.error('Error message:', error.message);
          
        
      }
    }
  };
  

  // 新增/編輯講師
  const handleSave = async () => {
    // 清理表單數據（空字符串轉換為 null，並確保數據類型匹配）
    const cleanedFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, value === '' ? null : value])
    );
  
    try {
      if (selectedLecturer) {
        // 編輯模式
        const response = await axios.put(`/api/lecturers/${selectedLecturer.l_id}`, cleanedFormData, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setLecturers((prevLecturers) =>
          prevLecturers.map((lecturer) =>
            lecturer.l_id === selectedLecturer.l_id ? { ...lecturer, ...response.data } : lecturer
          )
        );
        setSuccessMessage(response.data.message);
  
      } else {
        // 新增模式 (using POST instead of PUT)
        const response = await axios.post('/api/lecturers', cleanedFormData, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        
        
  
        setSuccessMessage(response.data.message);
      }
  
      
      setTimeout(() => setSuccessMessage(''), 3000); // 3 秒後清除成功消息
      fetchCompanies(1);
      setShowModal(false); // 關閉 Modal
      
  
            
    } catch (error) {
      //console.error('Error saving lecturer:', error.response ? error.response.data : error.message);
      alert('儲存講師信息時出現錯誤，請稍後重試');
    }
  };
  

  // 打開 Modal
  const openModal = (lecturer = null) => {
    setSelectedLecturer(lecturer);
    if (lecturer) {
      setFormData(lecturer);
    } else {
      setFormData({
      
      });
    }
    setShowModal(true);
  };

 

  return (
    <div className="container-fluid mt-4">
         {/* 顯示成功消息 */}
    {successMessage && (
      <div className="alert alert-success" role="alert">
        {successMessage}
      </div>
    )}
      
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋講師資料..."
          value={filter}
          onChange={(e) => {
            setLecturers([]);
            setPage(1);
            setFilter(e.target.value);
          }}
        />
        {hasPermission(user, 'EDIT_ROLE') && (
        <Button variant="success" size="sm" className="me-2" onClick={() => openModal()}>
          新增
        </Button>
        )}
      </div>
      

      <Table striped bordered hover variant="dark" className="table-striped table-dark">
        <thead>
          <tr>
          <th onClick={() => handleSort('l_id')}>編號</th>
          <th onClick={() => handleSort('lm_name')}>類別</th>
          <th onClick={() => handleSort('l_name')}>講師姓名</th>
          <th onClick={() => handleSort('l_experience')}>經驗</th>
          <th onClick={() => handleSort('l_currentpost')}>目前職位</th>
          {hasPermission(user, 'EDIT_ROLE') && (
           <>
          <th onClick={() => handleSort('l_tel')}>電話</th>
          <th onClick={() => handleSort('l_mobile')}>手機</th>
          <th>操作</th>
              
          </>
          )}
          </tr>
        </thead>
        <tbody>
          {lecturers.map((lecturer, index) => (
            <tr
              key={lecturer.l_id}
              
            >
              <td>{lecturer.l_id}</td>
              <td>{lecturer.lm_name}</td>
              <td>{lecturer.l_name}</td>
              <td>{lecturer.l_experience}</td>
              <td>{lecturer.l_currentpost}</td>
              {hasPermission(user, 'EDIT_ROLE') && (
              <>
              <td>{lecturer.l_tel}</td>
              <td>{lecturer.l_mobile}</td>
              <td>
                <Button
                  variant="info"
                  size="sm" className="me-2" 
                  onClick={() => openModal(lecturer)}
                >
                  編輯
                </Button>
                <Button
                  variant="danger"
                  size="sm" className="me-2" 
                  onClick={() => handleDelete(lecturer.l_id)}
                >
                  刪除
                </Button>
              </td>
              </>
              )}
                            
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan="14" className="text-center">
                加載中...
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Modal for Adding/Editing Lecturer */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedLecturer ? '編輯講師' : '新增講師'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3">
  <Form.Label>講師群組</Form.Label>
  <Form.Control
    type="text"
    value={formData.l_group}
    onChange={(e) =>
      setFormData({ ...formData, l_group: e.target.value })
    }
  />
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>講師姓名</Form.Label>
  <Form.Control
    type="text"
    value={formData.l_name}
    onChange={(e) =>
      setFormData({ ...formData, l_name: e.target.value })
    }
  />
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>講師編號</Form.Label>
  <Form.Control
    type="text"
    value={formData.l_no}
    onChange={(e) =>
      setFormData({ ...formData, l_no: e.target.value })
    }
  />
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>性別</Form.Label>
  <Form.Control
    type="text"
    value={formData.l_sex}
    onChange={(e) =>
      setFormData({ ...formData, l_sex: e.target.value })
    }
  />
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>電話</Form.Label>
  <Form.Control
    type="text"
    value={formData.l_tel}
    onChange={(e) =>
      setFormData({ ...formData, l_tel: e.target.value })
    }
  />
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>傳真</Form.Label>
  <Form.Control
    type="text"
    value={formData.l_fax}
    onChange={(e) =>
      setFormData({ ...formData, l_fax: e.target.value })
    }
  />
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>手機</Form.Label>
  <Form.Control
    type="text"
    value={formData.l_mobile}
    onChange={(e) =>
      setFormData({ ...formData, l_mobile: e.target.value })
    }
  />
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>經驗</Form.Label>
  <Form.Control
    type="text"
    value={formData.l_experience}
    onChange={(e) =>
      setFormData({ ...formData, l_experience: e.target.value })
    }
  />
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>目前職位</Form.Label>
  <Form.Control
    type="text"
    value={formData.l_currentpost}
    onChange={(e) =>
      setFormData({ ...formData, l_currentpost: e.target.value })
    }
  />
</Form.Group>


<Form.Group className="mb-3">
  <Form.Label>備註</Form.Label>
  <Form.Control
    type="text"
    value={formData.l_remark}
    onChange={(e) =>
      setFormData({ ...formData, l_remark: e.target.value })
    }
  />
</Form.Group>



          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" className="me-2" onClick={() => setShowModal(false)}>
            取消
          </Button>
          <Button variant="primary"  size="sm" className="me-2" onClick={handleSave}>
            儲存
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LecturerTable;
